import axios from '../utils/axios';

export const ReminderCreate = async (data) => {
  const response = await axios.post('/api/reminder/create', data);
  return response;
};
export const ReminderList = async () => {
  const response = await axios.get('/api/reminder/list');
  return response;
};
