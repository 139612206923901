import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  CompanySetting,
  LeadListPage,
  LeadCreatePage,
  LeadEditPage,
  LeadProfilePage,
  PricingPage,
  PaymentPage,
  Page500,
  Page404,
  Page403,
  MaintenancePage,
  ComingSoonPage,
  HomePage,
  AboutPage,
  ContactPage,
  FaqsPage,
  OrderCreatePage,
  OrderListPage,
  OrderEditPage,
  OrderLeadCreatePage,
  OrderExpiredPage,
  OrderExpiredAllPage,
  OrderViewPage,
  BankingPage,
  CalendarPage,
  AccountExpensePage,
  AccountListPage,
  // AccountNewPage,
  EcommerceProductListPage,
  EcommerceProductCreatePage,
  UserAccountPage,
  InvitePage,
  Payment,
  CustomersPage,
  SalesPage,
  PerformancePage,
  FileManagerPage,
  FileManagerDrivePage,
  InvoiceDownload,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'banking', element: <BankingPage /> },
        { path: 'calendar', element: <CalendarPage /> },
        {
          path: 'dashboard',
          children: [
            { element: <Navigate to="/dashboard/dashboard/sales" replace />, index: true },
            { path: 'sales', element: <SalesPage /> },
            { path: 'performance', element: <PerformancePage /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { element: <Navigate to="/dashboard/orders/list" replace />, index: true },
            { path: 'list', element: <OrderListPage /> },
            { path: 'new', element: <OrderCreatePage /> },
            { path: ':id/edit', element: <OrderEditPage /> },
            { path: ':id/new', element: <OrderLeadCreatePage /> },
            { path: 'expired', element: <OrderExpiredPage /> },
            { path: 'expired/all', element: <OrderExpiredAllPage /> },
            { path: ':id/view', element: <OrderViewPage /> },
            {path:'invoice/download',element:<InvoiceDownload/>}
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace />, index: true },
            // { path: 'profile', element: <UserProfilePage /> },
            // { path: 'cards', element: <UserCardsPage /> },
            // { path: 'list', element: <UserListPage /> },
            // { path: 'new', element: <UserCreatePage /> },
            // { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'accounts',
          children: [
            { element: <Navigate to="/dashboard/accounts/list" replace />, index: true },
            { path: 'expense', element: <AccountExpensePage /> },
            { path: 'list', element: <AccountListPage /> },
            // { path: 'new', element: <AccountNewPage /> },
          ],
        },
        {
          path: 'products',
          children: [
            { element: <Navigate to="/dashboard/products/list" replace />, index: true },
            { path: 'list', element: <EcommerceProductListPage /> },
            { path: 'new', element: <EcommerceProductCreatePage /> },
          ],
        },
        { path: 'company-setting', element: <CompanySetting /> },
        {
          path: 'files-manager',
          children: [
            { element: <Navigate to="/dashboard/files-manager/storage" replace />, index: true },
            { path: 'storage', element: <FileManagerPage /> },
            { path: 'drive', element: <FileManagerDrivePage /> },
            { path: 'drive/:id', element: <FileManagerDrivePage /> },
            {path:'storage/:id', element: <FileManagerPage />}
          ],
        },

        {
          path: 'lead',
          children: [
            { element: <Navigate to="/dashboard/lead/list" replace />, index: true },
            { path: 'list', element: <LeadListPage /> },
            { path: 'new', element: <LeadCreatePage /> },
            { path: 'customers', element: <CustomersPage /> },
            { path: ':id/edit', element: <LeadEditPage /> },
            { path: ':id/profile', element: <LeadProfilePage /> },
          ],
        },
        // {
        //   path: 'invoice',
        //   children: [
        //     { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
        //     { path: 'list', element: <InvoiceListPage /> },
        //     { path: ':id', element: <InvoiceDetailsPage /> },
        //     { path: ':id/edit', element: <InvoiceEditPage /> },
        //     { path: 'new', element: <InvoiceCreatePage /> },
        //   ],
        // },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <ContactPage /> },
        { path: 'faqs', element: <FaqsPage /> },
        // Demo Components
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'pricing', element: <PricingPage /> },
        { path: ':id/payment', element: <Payment /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'invite/:id', element: <InvitePage /> },
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
